<template>
  <div class="sof-box-entities main-text bold display-table">
    <div class="table-row">
      <div class="table-cell">
        <GlScoreDot
          class="mr-2"
          :score="100"
          width="6px"
        />
      </div>
      <div class="table-cell">
        <span class="mr-2 fs-10">Hydra Marketplace</span>
      </div>
      <div class="table-cell">
        <span class="mr-1">100 BTC</span>
      </div>
      <div class="table-cell text-end-last">
        <span class="ml-1"> 86%</span>
      </div>
    </div>
    <div class="table-row">
      <div class="table-cell">
        <GlScoreDot
          class="mr-2"
          :score="50"
          width="6px"
        />
      </div>

      <div class="table-cell">
        <span class="mr-2 fs-10">Cryptonator.com</span>
      </div>
      <div class="table-cell">
        <span class="mr-1">10 BTC</span>
      </div>
      <div class="table-cell text-end-last">
        <span class="ml-1"> 10%</span>
      </div>
    </div>
    <div class="table-row">
      <div class="table-cell">
        <GlScoreDot
          class="mr-2"
          :score="1"
          width="6px"
        />
      </div>
      <div class="table-cell">
        <span class="mr-2 fs-10">Binance.com</span>
      </div>
      <div class="table-cell">
        <span class="mr-1"> 1 BTC</span>
      </div>
      <div class="table-cell text-end-last">
        <span class="ml-1"> 1%</span>
      </div>
    </div>
  </div>
</template>

<script>
//Components
import GlScoreDot from '@/components/gl-score-dot'

export default {
  components: {
    GlScoreDot,
  },
}
</script>

<style>
.sof-box-entities {
  font-size: 8px;
  padding-top: 8px;
}
</style>
